<template>
  <div>
    <a-form
      class="iotplt-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col :span="8" :xxl="6">
          <a-form-item label="">
            <a-input
              v-decorator="['iccid_or_phone_number', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入ICCID或SIMID或手机号"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="生命周期">
            <a-select
              v-decorator="['life_cycle']"
              allow-clear
              @focus="fetchLifeCycleOptions"
              :loading="loadingLifeCycleOptions"
            >
              <a-select-option
                v-for="option in lifeCycleOptions"
                :key="option.slug"
                :value="option.slug"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="联网状态">
            <a-select
              v-decorator="['net_status']"
              allow-clear
              @focus="fetchNetStatusOptions"
              :loading="loadingNetStatusOptions"
            >
              <a-select-option
                v-for="option in netStatusOptions"
                :key="option.slug"
                :value="option.slug"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="入池状态">
            <a-select
              v-decorator="['is_into_pool']"
              allow-clear
            >
              <a-select-option
                v-for="option in isIntoPoolOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
              <a-button :style="{ fontSize: '12px', lineHeight: '32px' }" @click="toggle">
                更多条件
                <a-icon :type="expand ? 'up' : 'down'" />
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { findLifeCycleOptions, findNetStatusOptions } from '@/api/common_options'
import { findIsIntoPoolOptions } from '@/api/traffic_pool'

export default {
  name: 'SearchTrafficPoolSimCard',
  data() {
    return {
      expand: false,
      lifeCycleOptions: [],
      netStatusOptions: [],
      isIntoPoolOptions: findIsIntoPoolOptions(),
      loadingLifeCycleOptions: false,
      loadingNetStatusOptions: false,
      form: this.$form.createForm(this, { name: 'traffic_pool_sim_card_search' })
    }
  },
  methods: {
    toggle() {
      this.expand = !this.expand
    },

    // 加载生命周期选项数据
    fetchLifeCycleOptions() {
      if (this.lifeCycleOptions.length === 0) {
        this.loadingLifeCycleOptions = true
        findLifeCycleOptions().then(res => {
          if (res.code === 0) {
            this.lifeCycleOptions = res.data
            this.loadingLifeCycleOptions = false
          }
        })
      }
    },

    // 加载联网状态选项数据
    fetchNetStatusOptions() {
      if (this.netStatusOptions.length === 0) {
        this.loadingNetStatusOptions = true
        findNetStatusOptions().then(res => {
          if (res.code === 0) {
            this.netStatusOptions = res.data
            this.loadingNetStatusOptions = false
          }
        })
      }
    },

    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
